<template>
  <Stack direction="col" align="center" gap="xs" class="w-full h-full px-[100px] mt-2xl">
    <div class="bg-mkm-yellow-default rounded-icon">
      <NuxtImg src="/cart/basket.svg" />
    </div>
    <Heading
      class="text-center text-mkm-blue-default mb-2xs"
      weight="semi"
      size="xs"
      tag="h2"
      data-test-id="emptyCartTitle"
      >Your basket is empty</Heading
    >
    <Text v-if="!user" class="text-center mb-lg">
      <NuxtLink class="text-mkm-blue-light" to="/login" @click="setBasketTray">Sign in</NuxtLink> to view items you may
      have added from another device or continue shopping below.
    </Text>
    <Button variant="secondary" data-test-id="continueShoppingBtn" @click="closeTray">Continue shopping</Button>
  </Stack>
</template>

<script setup lang="ts">
const { setBasketTray } = useUIState();
const { user } = useUser();
const closeTray = () => {
  setBasketTray();
};
</script>
<style>
.rounded-icon {
  border-radius: 50%;
  height: 67px;
  width: 67px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
